import { createStore } from 'vuex'

const store = createStore({
    // 全局state
    state () {
        return {
            // Idcompany:null,
            odataHeaders:[
                {Authorization:'Bearer '}
            ],
            // userId:'',
            // userName:'',
            unReadMsgNum:0,
            userInfo:{},
            menuList:[],
            btnPermissions:[],
            roles: [], // 角色操作权限
            // 暂存字段（后接口删除）--测试form-js
            saveSchema:{
                "schemaVersion": 1,
                "exporter": {
                    "name": "form-js",
                    "version": "0.1.0"
                },
                "components": [
                    {
                        "key": 'creditor',
                        "label": '文本1',
                        "type": 'textfield',
                        "validate": {
                            "required": true
                        }
                    }
                ],
                "type": "default"
            },
            saveSchemaData:{},
            keycloakList:{},
            // 测 formcreateJSON
            formCreateJson:[
                {
                    "type": "input",
                    "field": "Fbir62cnnt08n",
                    "title": "输入框",
                    "info": "",
                    "$required": false,
                    "_fc_drag_tag": "input",
                    "hidden": false,
                    "display": true,
                    "value": "123"
                },
                {
                    "type": "inputNumber",
                    "field": "Fcz862cnnt8ds",
                    "title": "计数器",
                    "info": "",
                    "$required": false,
                    "_fc_drag_tag": "inputNumber",
                    "hidden": false,
                    "display": true,
                    "value": 1
                },
                {
                    "type": "radio",
                    "field": "Fsjf62cnnts0t",
                    "title": "单选框",
                    "info": "",
                    "$required": false,
                    "options": [
                        {
                            "label": "选项1",
                            "value": 1
                        },
                        {
                            "label": "选项2",
                            "value": 2
                        }
                    ],
                    "_fc_drag_tag": "radio",
                    "hidden": false,
                    "display": true,
                    "value": 1,
                    "props": {
                        "_optionType": 2,
                        "textColor": "red",
                        "fill": "#000"
                    }
                },
                {
                    "type": "select",
                    "field": "Fp6j1oniknbfku",
                    "title": "选择器",
                    "info": "",
                    "effect": {
                        "fetch": {
                            "action": "http://192.168.0.218:8090/api/stationLevel",
                            "method": "GET",
                            "data": {},
                            "headers": {},
                            "to": "options"
                        }
                    },
                    "$required": false,
                    "props": {
                        "_optionType": 1
                    },
                    "_fc_drag_tag": "select",
                    "hidden": false,
                    "display": true,
                    "value": 37
                },
                {
                    "type": "datePicker",
                    "field": "Fc591onu9g8t94",
                    "title": "日期选择器",
                    "info": "",
                    "$required": false,
                    "props": {
                        "type": "datetime"
                    },
                    "_fc_drag_tag": "datePicker",
                    "hidden": false,
                    "display": true,
                    "value": "2024-03-25 00:00:00"
                },
                {
                    "type": "timePicker",
                    "field": "Fqbj1onu9gd2yj",
                    "title": "时间选择器",
                    "info": "",
                    "$required": false,
                    "_fc_drag_tag": "timePicker",
                    "hidden": false,
                    "display": true,
                    "value": "14:42:36"
                },
                {
                    "type": "input",
                    "field": "Fzcw1oniknf5op",
                    "title": "输入框",
                    "info": "",
                    "$required": false,
                    "props": {
                        "type": "textarea",
                        "resize": "vertical",
                        "autocomplete": "on",
                        "rows": 5
                    },
                    "_fc_drag_tag": "input",
                    "hidden": false,
                    "display": true,
                    "value": "123345asfdasdfaert阿德撒发达手动阀阿斯顿发顺丰手动阀十分啊沙发沙发撒古典风格撒手动阀手动阀阿斯弗手动发射点发企鹅表达反对发射点发撒旦发个第三个色弱阿斯顿发顺丰染发手动阀十分大哥天人合一见过好几个愉快充个电非很多人土豪规划合法的更好地发挥一天非官方的换个地方手动阀",
                    "validate": [
                        {
                            "type": "string"
                        }
                    ]
                },
                {
                    "type": "rate",
                    "field": "F02m1onua80yqi",
                    "title": "评分",
                    "info": "",
                    "$required": false,
                    "_fc_drag_tag": "rate",
                    "hidden": false,
                    "display": true,
                    "value": 4.5,
                    "props": {
                        "allowHalf": true,
                        "voidColor": "red",
                        "disabledVoidColor": "",
                        "showScore": true,
                        "textColor": "red"
                    }
                },
                {
                    "type": "tree",
                    "field": "Fdxb1onuaob27l",
                    "title": "树形控件",
                    "info": "",
                    "effect": {
                        "fetch": ""
                    },
                    "$required": false,
                    "props": {
                        "props": {
                            "label": "label"
                        },
                        "showCheckbox": true,
                        "nodeKey": "id",
                        "data": [
                            {
                                "label": "选项1",
                                "id": 1,
                                "children": []
                            },
                            {
                                "label": "选项2",
                                "id": 2,
                                "children": []
                            }
                        ]
                    },
                    "_fc_drag_tag": "tree",
                    "hidden": false,
                    "display": true
                },
                {
                    "type": "upload",
                    "field": "Fo171onuaop8mo",
                    "title": "上传",
                    "info": "",
                    "$required": false,
                    "props": {
                        "action": "",
                        "autoUpload": false
                    },
                    "_fc_drag_tag": "upload",
                    "hidden": false,
                    "display": true
                }
            ],
            formCreateOption:{
                "form": {
                    "labelPosition": "right",
                    "size": "default",
                    "labelWidth": "125px",
                    "hideRequiredAsterisk": false,
                    "showMessage": true,
                    "inlineMessage": false
                },
                "submitBtn": {
                    "show": true,
                    "innerText": "提交"
                },
                "resetBtn": {
                    "show": false,
                    "innerText": "重置"
                }
            }
        }
    },
    mutations:{
        // upIdcompany(state,payload){
        //    state.Idcompany = payload.newtext
        // },
        setOdataHeaders(state,token){
            state.odataHeaders[0].Authorization='Bearer '+token;
            window.localStorage.setItem('token',token);
        },
        // setUserId(state,userId){
        //     state.userId = userId;
        // },
        // setUserName(state,username){
        //   state.userName = username;
        // },
        setUserInfo(state,data){
            // console.log(data);
            state.userInfo = {
                userId:data.id,
                userName:data.name,
                loginName:data.loginName,
                companyId:data.companyId,
                companyName:data.companyName,
                departmentId:data.departmentId,
                departmentName:data.departmentName,


            };
        },
        setRoles(state,roleValue){ // 角色操作权限存储
            state.roles = roleValue;
        },
        setSchema(state,data){
            state.saveSchema=data;
        },
        setSchemaData(state,data){
            state.saveSchemaData=data;
        },
        setKeycloakList(state,data){
            state.keycloakList=data
        },
        setMenuList(state,data){
            state.menuList=data;
        },
        setBtnPermissions(state,data){
            state.btnPermissions=data;
        },
        addUnReadMsgNum(state){
            state.unReadMsgNum++;
        },
        resetUnReadMsgNum(state,data){
            state.unReadMsgNum=data;
        },
    },
    actions:{

    },
    getters:{
        getOdataHeader(state){
            return state.odataHeaders;
        },
		getRole(state){ // 角色操作权限获取
			return state.roles[1];
		},
        getUserId(state){
            return state.userId;
        },
        getUserName(state){
            return state.userName;
        },
        getBtnPermissions(state){
            return state.btnPermissions;
        }
    },

    modules:{

    }
})

export default store;
