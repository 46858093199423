<template>
  <router-view></router-view>

  <!--<router-view/>-->
  <!--页面返回不刷新-->
<!--  <keep-alive>-->
<!--    <router-view v-if="$route.meta.keepAlive"></router-view>-->
<!--  </keep-alive>-->
<!--  <router-view v-if="!$route.meta.keepAlive"></router-view>-->
</template>

<script>
import { L10n,setCulture,loadCldr } from "@syncfusion/ej2-base";
// 中文化
import EJ2_LOCALE from './assets/json/zh.json';
setCulture("zh");

loadCldr(
    require('cldr-core/supplemental/numberingSystems.json'),
    require('cldr-dates-full/main/zh/ca-generic.json'),
    require('cldr-dates-full/main/zh/dateFields.json'),
    require('cldr-dates-full/main/zh/ca-gregorian.json'),
    require('cldr-dates-full/main/zh/timeZoneNames.json'),
    require('cldr-core/supplemental/weekData.json'),
);

L10n.load({ zh: EJ2_LOCALE.zh });


export default{
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(sessionStorage.getItem('store'))
          )
      )
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  mounted(){},

  methods:{}
}
</script>

<style>

@import "@syncfusion/ej2-base/styles/material.css";
/*@import "@syncfusion/ej2-base/styles/bootstrap5.css";*/
@import "@syncfusion/ej2-vue-navigations/styles/material.css";
/*@import "@syncfusion/ej2-vue-navigations/styles/bootstrap5.css";*/
@import "@syncfusion/ej2-buttons/styles/material.css";
/*@import "@syncfusion/ej2-buttons/styles/bootstrap5.css";*/
@import "@syncfusion/ej2-vue-popups/styles/material.css";
@import "@syncfusion/ej2-vue-grids/styles/material.css";
/*@import "@syncfusion/ej2-vue-grids/styles/bootstrap5.css";*/
@import '@syncfusion/ej2-vue-layouts/styles/material.css';
@import "@syncfusion/ej2-base/styles/material.css";
/*@import "@syncfusion/ej2-base/styles/bootstrap5.css";*/
/*@import "@syncfusion/ej2-inputs/styles/material.css";*/
@import "@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "@syncfusion/ej2-vue-dropdowns/styles/material.css";
/*@import "@syncfusion/ej2-vue-dropdowns/styles/bootstrap5.css";*/
@import "@syncfusion/ej2-calendars/styles/material.css";
@import '@syncfusion/ej2-vue-calendars/styles/material.css';
@import "@syncfusion/ej2-vue-schedule/styles/material.css";
@import "@syncfusion/ej2-vue-treegrid/styles/material.css";




#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  height:100%;
}
</style>
