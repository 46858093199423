import axios from 'axios';

class CamundaRest {
    // static ENGINE_REST_ENDPOINT = '/engine-rest/';
    // static ENGINE_REST_ENDPOINT = 'http://192.168.3.198:8080/engine-rest/';
    // static ENGINE_REST_ENDPOINT = '/engine-rest/';
    static ENGINE_REST_ENDPOINT = process.env.NODE_ENV == "development" ?  window.gDev.CamundaUrl :window.gEnv.CamundaUrl;
    // 获取流程定义
    static getProcessDefinitions() {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-definition`;
    }
    // 根据processDefinitionId 获取流程信息
    static getProcessDefinition(processDefinitionId) {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-definition/${processDefinitionId}`;
    }
    // 上传bpmn文件（部署流程）
    static deployProcessDefinition() {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}deployment/create`;
    }
    // 获取流程图
    static getProcessBpmnXml(processId){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-definition/${processId}/xml`;
    }
    // 获取任务列表（待完成的）
    static getTasks() {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}task`;
    }
    // 获取任务列表（历史任务）
    static getHistoryTasks() {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}history/task`;
    }
    // 根据taskId 获取相关表单的形式
    static getTaskFormKey(taskId) {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}task/${taskId}/form`;
    }
    // 获取任务表单
    static getRenderForm(taskId){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}task/${taskId}/rendered-form`;
    }
    static getTaskFormCa(taskId){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}task/${taskId}/deployed-form`;
    }
    // 获取任务表单的变量
    static getTaskFormVariables(taskId){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}task/${taskId}/form-variables`;
    }
    // 提交任务表单的变量值
    static postTaskFormVariables(taskId){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}task/${taskId}/submit-form`;
    }
    static putTaskVar(taskId,varName){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}task/${taskId}/localVariables/${varName}`;
    }
    // 获取进程实例的变量值
    static getProcessInstanceVariables(processInstanceId){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-instance/${processInstanceId}/variables`;
    }
    // 获取进程的表单形式
    static getProcessDefinitionFormKey(processDefinition){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-definition/${processDefinition}/startForm`;
    }
    // 获取用户操作历史记录
    static getHistoryUserOperation(){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}history/user-operation`;
    }
    // 获取历史详情
    static getHistoryDetail(){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}history/detail`;
    }
    // 获取历史变量
    static getHistoryVariables(){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}history/variable-instance`;
    }
    // 获取历史活动实例数据
    static getHistoryActivityIns(){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}history/activity-instance`;
    }
    // 获取发起流程的camunda form配置表单
    static getDeployedForm(processDefinitionId){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-definition/${processDefinitionId}/deployed-start-form`;
    }




    // ---------- GQ ------------
    // 获取发起流程html页面
    static getRenderHtml(taskId){
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-definition/${taskId}/rendered-form`;
    }
    // 提交发起的流程表单
    static postProcessInstance(processDefinitionKey, values) {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-definition/key/${processDefinitionKey}/start`;
    }
    static postProcessInstanceI(processDefinitionId, values) {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-definition/${processDefinitionId}/start`;
    }
    // 获取我的历史流程表单
    static getStartProcess(user) {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}history/process-instance`;
    }
    // 获取我的流程表单
    static getProcessIndtance() {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-instance`;
    }
    // 获取当前审批任务
    static getCurrentTask(id) {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}task`;
    }
    // 获取当前审批任务
    static upload(id) {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}task/${id}/attachment/create`;
    }
    // 获取流程实例详情数据
    static getProcessDetail(id) {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-instance/${id}/variables`;
    }
    // 删除流程实例
    static deleteProcessInstance(id) {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}process-instance/${id}`;
    }
    // 上传附件回显
    static getTaskAttachment(id) {
        return `${CamundaRest.ENGINE_REST_ENDPOINT}task/${id}/attachment`;
    }


}

export default CamundaRest;

