import instance from './axiosConfig';

export default {
    get: function (url, data) {
        return instance({
            url: url, // 假地址 自行替换
            method: 'get',
            params: data
        });
    },
    post: function (url, data) {
        return instance({
            url: url, // 假地址 自行替换
            method: 'post',
            data: data
        });
    },
    postform: function (url, data) {
        return instance({
            url: url, // 假地址 自行替换
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    patch: function (url, data) {
        return instance({
            url: url, // 假地址 自行替换
            method: 'patch',
            data: data
        });
    },
    put: function (url, data) {
        return instance({
            url: url, // 假地址 自行替换
            method: 'put',
            data: data
        });
    },
    delete: function (url, data) {
        return instance({
            url: url, // 假地址 自行替换
            method: 'delete',
            params: data
        });
    },
}
