import Mock from 'mockjs';

let menuList = [
  {
    "text": "首页",
    "iconCss": "e-icons e-home",
    "el-icon": "Folder",
    "routerName":"home"
  },
  {
    "text": "企业组织架构",
    "iconCss": "e-icons e-grid-view",
    "routerName":"enterpriseOrganization",
    "items": [
      {
        "text": "公司组织架构",
        "routerName":"companyManage"
      },
      {
        "text": "部门组织架构",
        "routerName":"departmentManage"
      },
      {
        "text": "职位组织架构",
        "routerName":"positionManage"
      },
      {
        "text": "员工信息",
        "routerName":"employeeManage"
      },
      {
        "text": "分组管理",
        "routerName":"groupEmployeeManage"
      },
      {
        "text": "业务角色",
        "routerName":"businessRole"
      }
    ]
  },
  // {
  //   "text": "企业组织架构(旧)",
  //   "iconCss": "e-icons e-grid-view",
  //   "routerName":"enterprise",
  //   "roles":["R_HR_R","R_HR_W"],
  //   "items": [
  //     {
  //       "text": "公司信息",
  //       "routerName":"companyInfo"
  //     },
  //     {
  //       "text": "部门设置",
  //       "routerName":"departmentInfo"
  //     },
  //     {
  //       "text": "员工信息",
  //       "routerName":"employeesInfo"
  //     }
  //   ]
  // },
  {
    "text": "流程管理",
    "iconCss": "e-icons e-description",
    "routerName":"processManage",
    "items": [
      {
        "text": "流程分类",
        "routerName":"processClassify"
      },
      {
        "text": "流程表单",
        "routerName":"processFormConfig"
      },
      {
        "text": "流程模型",
        "routerName":"processModel1"
      },
      // {
      //   "text": "流程模型",
      //   "routerName":"processModel"
      // },
    ]
  },
  {
    "text": "任务管理",
    "iconCss": "e-icons e-description",
    "routerName":"taskHanding",
    "items": [
      {
        "text": "设置定时任务",
        "routerName": "timedTasks"
      },
      {
        "text": "我发起的",
        "routerName":"initiateProcess"
      },
      {
        "text": "我接收的",
        "routerName":"myTimedTasks",
        "items": [
          {
            "text": "待办任务",
            "routerName":"timedTasksTodo"
          },
          {
            "text": "已办任务",
            "routerName":"timedTasksCompleted"
          },
          {
            "text": "抄送任务",
            "routerName":"timedTasksCC"
          }
        ]
      },
      {
        "text": "消息通知",
        "routerName":"messageNotification"
      },
      {
        "text": "派发/申领任务（待做）",
        "routerName":"assignTasks"
      },
      // {
      //   "text":"之前做的",
      //   "routerName":"myProcess",
      //   "items":[
      //     {
      //       "text": "我的流程",
      //       "routerName":"myProcess"
      //     },
      //     {
      //       "text": "待办任务",
      //       "routerName":"todoTasks"
      //     },
      //     {
      //       "text": "已办任务",
      //       "routerName":"completedTasks"
      //     },
      //     {
      //       "text": "抄送任务",
      //       "routerName":"myReadOnly"
      //     }
      //   ]
      // }
    ]
  },
  /*
  {
    "text": "任务管理（未使用）",
    "iconCss": "e-icons e-description",
    "routerName":"taskManage",
    "items": [
      {
        "text": "派发任务",
        "routerName":"disPatchTasksInfo"
      },
      {
        "text": "我的任务",
        "routerName":"myTaskInfo"
      }
    ]
  },
   */
  {
    "text": "运维管理",
    "iconCss": "e-icons e-page-setup",
    "routerName": "operationManage",
    "items": [
      {
        "text": "运维站点",
        "routerName": "operationStation",
        "items":[
          {
            "text": "站点信息",
            "routerName": "stationInfo"
          },
          {
            "text": "IoT站点配置",
            "routerName": "iotStationConfig"
          },
          {
            "text": "站点主题匹配",
            "routerName": "stationMqtt"
          }
        ]
      },
    ]
  },
  /*{
    "text": "运维管理",
    "iconCss": "e-icons e-page-setup",
    "routerName":"operation",
    "items": [
      {
        "text": "人员清单",
        "routerName":"personnelList"
      },
      {
        "text": "场地清单",
        "routerName":"venueList"
      },
      {
        "text": "设备清单",
        "routerName":"equipmentStatus"
      },
      {
        "text": "排班",
        "routerName":"dutyPersonnel"
      },
      {
        "text": "人员签到",
        "routerName":"checkInOut"
      },
      {
        "text": "巡检信息",
        "routerName":"patrolInfo"
      },
      {
        "text": "故障报告",
        "routerName":"faultReport"
      },
      {
        "text": "系统预警",
        "routerName":"earlyWarning"
      },
      {
        "text": "系统报警",
        "routerName":"alarmInfo"
      },
      {
        "text": "系统通信状态",
        "routerName":"communicationStatus"
      },
      {
        "text": "维修管理",
        "routerName":"repair"
      },
      {
        "text": "运维药剂出入库",
        "routerName":"drugInventory"
      },
      {
        "text": "化验药品管理",
        "routerName":"laboratoryDrugMage"
      },
      {
        "text": "用药记录",
        "routerName":"medRecord"
      },
      {
        "text": "备品备件",
        "routerName":"spareParts"
      }
    ]
  },

   */
  {
    "text": "数据统计",
    "iconCss": "e-icons e-table-insert-row",
    "routerName":"statisticalData",
  },
  {
    "text": "采样化验",
    "iconCss": "e-icons e-table-insert-row",
    "routerName":"laboratoryFilling",
    "roles":["R_WATER_SAMPLE_R","R_WATER_SAMPLE_W","R_WATER_CHECK_R","R_WATER_CHECK_W"]
  },
  {
    "text": "字典配置",
    "iconCss": "e-icons e-paste-text-only",
    "routerName":"dictConfig",
    "roles":["R_DIC_WATER_SAMPLE_POS_R","R_DIC_WATER_SAMPLE_POS_W","R_DIC_WATER_CHECK_R","R_DIC_WATER_CHECK_W"],
    "items": [
      // {
      //   "text": "固定字典配置",
      //   "routerName":"commonDict"
      // },
      {
        "text": "业务字典配置",
        "routerName":"businessDict"
      },
      {
        "text": "IOT相关配置",
        "routerName":"iotRelationDict"
      }
    ]
  },
  {
    "text": "系统管理",
    "iconCss": "e-icons e-settings",
    "routerName":"systemSettings",
    "items": [
      {
        "text": "菜单管理",
        "routerName":"menuManage"
      },
      {
        "text": "角色管理",
        "routerName":"roleManage"
      },
      {
        "text": "主题换肤",
        "routerName":"themeChanging"
      }
    ]
  },
  /*
  {
    "text": "测试代码",
    "iconCss": "e-icons e-paste-text-only",
    "routerName":"test",
    "items": [
      {
        "text": "树结构",
        "routerName":"treeGrid"
      },
      {
        "text": "grpc",
        "routerName":"grpc"
      }
    ]
  }
  */
]


Mock.mock('/api/menuData','GET', menuList);


export default {
  menuList
}

